<template>
  <div id="wizard" class="">
    <header>
    <v-layout class="" align-center justify-center>

      <v-flex class="stats flex-auto">
        <v-layout align-center justify-space-between>
          <v-flex class="step-number">{{currentMarker.index+1 || 0}}</v-flex>
          <v-flex class="slash">/</v-flex>
          <v-flex class="step-total">{{markers.length}}</v-flex>
        </v-layout>
      </v-flex>

      <v-flex class="px-2">
        <v-progress-linear
        color="white"
        height="5"
        :value="((currentMarker.index+1)/(markers.length))*100">
      </v-progress-linear>
    </v-flex>

    <v-flex class="flex-auto wizard-btn">
      <v-btn small class="px-1 secondary--text text--darken-3" value="step" color="white" depressed @click="toggle_view = 'steps'" v-if="toggle_view == 'step'">
        <v-icon class="mr-1">horizontal_split</v-icon> All steps
      </v-btn>
      <v-btn small class="px-1 secondary--text text--darken-3" value="step" color="white" depressed @click="toggle_view = 'step'" v-if="toggle_view == 'steps'">
        <v-icon class="mr-1">looks_one</v-icon> Single step
      </v-btn>
    </v-flex>

    <v-flex class="flex-auto" v-if="toggle_view == 'false'">
      <v-btn-toggle tile group color="white" mandatory v-model="toggle_view">
        <!-- Step -->
        <v-btn small icon class="pa-0" value="step">
          <v-icon>looks_one</v-icon>
        </v-btn>
        <!-- All -->
        <v-btn small icon class="pa-0" value="steps">
          <v-icon>horizontal_split</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-flex>

    </v-layout>
    </header>


  </div>
</template>

<script>


export default {
  name: "wizard",
  props: ['markers', 'currentMarker', 'isPlaying', 'stepView'],
  data(){
    return{
      avatarSize: 36,
      currentIndex: 0,
      toggle_view:null,
    }
  },
  methods: {
    setIndex(index){
      this.currentIndex = index;
      return index
    },
    clickStep(marker){
      console.log('clicked', marker);
      let data = []
      data.element = false;
      data.marker = marker;
        this.$emit('onStepClicked', data)
    },
    startPlaying(){
      this.$emit('startPlaying', true)
    }
  },
  watch:{
    toggle_view: function(val){
      this.$emit('toggleView', val);
    }
  },
  mounted(){
    this.toggle_view = this.stepView
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#wizard{

  .video-header{
    height: 65px;
  }
  .wizard-btn .v-btn{
    background-color: rgba(255, 255, 255, .6)!important;
  }

  header{
    .layout{padding: 5px;}
    .stats{
      margin-right: 10px;
      font-size: 20px;
      font-weight: bold;
      color: white;
      .step-number{
        font-size: 150%;
      }
      .slash{
        opacity: .5;
        padding: 0 2px;
      }
      .step-total{
        opacity: .75;
        font-size: 75%;
      }
    }
    .v-item-group{
      .v-btn{
        margin: 0;
        padding: 0;
      }
    }
  }
  .order-first{
    order:0;
  }
  .order-last{
    order: 10;
  }
  .wizard{
    padding: 10px;
    .v-card{
      padding: 10px;
    }
    .v-avatar{
      margin: 0 8px 5px 5px;
      &:hover{
        cursor: pointer;
      }
    }
  }

}
</style>
